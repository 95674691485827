import { Link } from "react-router-dom";
import React from "react";

const Unavbar = () => {
    return (

      <>

        <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: '#685123' }} >
          <div className="container" id="nav">
            <Link to="/home" className="navbar-brand">
              <span className="topleft"><img src="assets/images/logo1re.png" alt="" className="logoname" /></span>
            </Link>
            <div class="btn custom-btn d-lg-none ms-auto me-2 dropdown">
              <Link class="dropdown-toggle text-white " role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
             <i class="bi bi-person-circle"></i> &nbsp;
              </Link>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ left: '14px' }}>
                <Link to="/dashboard" className="list-group-item ms-2" style={{ fontSize: '17px', fontWeight: '400' }} >
                  <i class="bi bi-house-door"></i>&nbsp; Dashboard
                </Link> <hr />
                <Link to="/login" className="list-group-item ms-2" style={{ fontSize: '17px', fontWeight: '400' }} >
                  <i class="bi bi-box-arrow-right"></i>&nbsp; Logout
                </Link>
              </ul>
            </div>
          
            <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon text-center" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav align-items-lg-center" id="navv">
                <li className="nav-item">
                  <Link to="/home" className="nav-link click-scroll"><i className="bi bi-house" />
                    <br />Home</Link>
                </li>
                <li className="nav-item dropdown">
                  <li className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-send-check-fill" /> <br /> Offers &amp; Discount
                  </li>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li id="linav">
                      <a className="dropdown-item" href > Hospital » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href="https://www.felixhospital.com/" target="_blank" rel="noopener noreferrer" >
                          1. Felix Healthcare </a>
                        <a className="dropdown-item" id="ah" href >
                          2. Yatharth Hospital </a>
                        <a className="dropdown-item" id="ah" href="https://arogyawomenchildclinic.com/" target="_blank" rel="noopener noreferrer" >
                          3. Arogya Clinic</a>
                        <a className="dropdown-item" id="ah" href="https://www.medindia.net/patients/hospital_search/j-s-tomar-memorial-hospital-pvt-ltd-noida-gautam-buddha-nagar-uttar-pradesh-21662-1.htm" target="_blank" rel="noopener noreferrer" >
                          4. J S Tomar Memorial Hospital Pvt. </a>
                        <a className="dropdown-item" id="ah" href="https://www.practo.com/noida/clinic/maheshwari-clinic-noida-sector-108" target="_blank" rel="noopener noreferrer" >
                          5. Maheshwari Clinic</a>
                        <a className="dropdown-item" id="ah" href="https://www.practo.com/noida/hospital/ganpati-hospital-salarpur-kalan" target="_blank" rel="noopener noreferrer" >
                          6. Ganpati Hospitaland Maternity Centre</a>
                        <a className="dropdown-item" id="ah" href="https://www.thecompanycheck.com/org/shiv-health-care/fa71819978" target="_blank" rel="noopener noreferrer" >
                          7. Shiv Clinic Gautam Buddha Nagar</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Tanvi Clinic And Diagnostic</a>
                        <a className="dropdown-item" id="ah" href >
                          9. Mehta Clinic</a>
                        <a className="dropdown-item" id="ah" href >
                          10. Ojjus Hospital</a>
                        <a className="dropdown-item" id="ah" href="https://www.medindia.net/patients/hospital_search/r-n-clinic-noida-uttar-pradesh-89160-1.htm#google_vignette" target="_blank" rel="noopener noreferrer" >
                          11. R.N. Clinic</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href >Restaurants » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. Domino's Pizza</a>
                        <a className="dropdown-item" id="ah" href >
                          2.HCL cafeteria</a>
                        <a className="dropdown-item" id="ah" href>
                          3. Food corner</a>
                        <a className="dropdown-item" id="ah" href>
                          4. McDonald's </a>
                        <a className="dropdown-item" id="ah" href>
                          5.Cafe Coffee Day</a>
                        <a className="dropdown-item" id="ah" href>
                          6. Nescafe</a>
                        <a className="dropdown-item" id="ah" href>
                          7. Coffee Day Express</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Sagar Ratna</a>
                        <a className="dropdown-item" id="ah" href >
                          9. Red Chillies</a>
                        <a className="dropdown-item" id="ah" href >
                          10. Mustos Cafe </a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href > Educational Institute » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. S.K.S School Noida
                        </a>
                        <a className="dropdown-item" id="ah" href >
                          2. Sardha University..</a>
                        <a className="dropdown-item" id="ah" href>
                          3. KKMII Stratford University</a>
                        <a className="dropdown-item" id="ah" href>
                          4. km Mayur School. </a>
                        <a className="dropdown-item" id="ah" href>
                          5. Amity University Noida</a>
                        <a className="dropdown-item" id="ah" href>
                          6. Government Post Graduate College Noida</a>
                        <a className="dropdown-item" id="ah" href>
                          7. Amity University Greater Noida</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Delhi Technical Campus</a>
                        <a className="dropdown-item" id="ah" href >
                          9. Dronacharya Group of Institutions</a>
                        <a className="dropdown-item" id="ah" href >
                          10.Accurate Institute of Technology</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href > Shopping Centre » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. Ganga Shopping Complex
                        </a>
                        <a className="dropdown-item" id="ah" href >
                          2. Nx One Mall</a>
                        <a className="dropdown-item" id="ah" href>
                          3. Svg Galleria</a>
                        <a className="dropdown-item" id="ah" href>
                          4. Vishal Mega Mart</a>
                        <a className="dropdown-item" id="ah" href>
                          5. Signature Global Mall Vaishali</a>
                        <a className="dropdown-item" id="ah" href>
                          6. Lotus Panache Shopping Complex</a>
                        <a className="dropdown-item" id="ah" href>
                          7. V2 Mall</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Logic Mall</a>
                        <a className="dropdown-item" id="ah" href >
                          9. Ten Dimensions</a>
                        <a className="dropdown-item" id="ah" href >
                          10. Saya Group</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href > Jewellery Showrooms » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. R C Jewellers
                        </a>
                        <a className="dropdown-item" id="ah" href >
                          2. Prem Ji Jewellers</a>
                        <a className="dropdown-item" id="ah" href>
                          3. Reliance Jewels</a>
                        <a className="dropdown-item" id="ah" href>
                          4. Prakreeti Jewellers</a>
                        <a className="dropdown-item" id="ah" href>
                          5. Jewels Mto</a>
                        <a className="dropdown-item" id="ah" href>
                          6. Gehna Gems And Jewellers</a>
                        <a className="dropdown-item" id="ah" href>
                          7. Dkaj Jewels</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Rajshree Jewellers</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href > Car Showroom &amp; Service Center » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. Bosch Car Service Centre
                        </a>
                        <a className="dropdown-item" id="ah" href >
                          2. MyTVS </a>
                        <a className="dropdown-item" id="ah" href>
                          3. A to Z Motor </a>
                        <a className="dropdown-item" id="ah" href>
                          4. Auto Car Repair</a>
                        <a className="dropdown-item" id="ah" href>
                          5. Car Nation Alpha Automobiles</a>
                        <a className="dropdown-item" id="ah" href>
                          6. Mooncrest N Care Private Limited</a>
                        <a className="dropdown-item" id="ah" href>
                          7. Ishwar Motors</a>
                        <a className="dropdown-item" id="ah" href >
                          8. G. S MOTORS</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href > Beauty &amp; Unisex Salon » </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <a className="dropdown-item" id="ah" href>
                          1. Beauty life unisex salon
                        </a>
                        <a className="dropdown-item" id="ah" href >
                          2.The Beauty Paradise Unisex Salon &amp; Academy </a>
                        <a className="dropdown-item" id="ah" href>
                          3. Beauty Town Unisex Salon </a>
                        <a className="dropdown-item" id="ah" href>
                          4.Beauty Looks Unisex Saloon (Msx Mall)</a>
                        <a className="dropdown-item" id="ah" href>
                          5. The Beauty Cottage Unisex Salon </a>
                        <a className="dropdown-item" id="ah" href>
                          6. Beauty And Cuts Unisex Salon </a>
                        <a className="dropdown-item" id="ah" href>
                          7. The Beauty Unisex Salon</a>
                        <a className="dropdown-item" id="ah" href >
                          8. Beauty Craze Unisex Salon</a>
                      </ul>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href="/home#commercial"> New Commercial Development »
                      </a>
                    </li>
                    <hr />
                    <li id="linav">
                      <a className="dropdown-item" href="/home#residential"> New Residential Development»
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link " href="https://www.ratnkunj.com/reality/post_property" target="_blank" rel="noopener noreferrer" ><i className="bi bi-basket2-fill" /><br /> Sell or Rent</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/home"><i className="bi bi-chat-left-text" /> <br />
                    Messages</Link>
                </li>
                <li className="nav-item dropdown">
                  <li className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-person-circle"></i> <br />  My Profile
                  </li>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to="/myprofile" className="list-group-item ms-2" style={{ fontSize: '17px', fontWeight: '400' }}>
                      <i class="bi bi-gear"></i>&nbsp; Account Setting
                    </Link> <hr />

                    <Link to="/dashboard" className="list-group-item ms-2" style={{ fontSize: '17px', fontWeight: '400' }} >

                      <i class="bi bi-house-door"></i>&nbsp; Dashboard
                    </Link> <hr />

                    <Link to="/login" className="list-group-item ms-2" style={{ fontSize: '17px', fontWeight: '400' }} >

                      <i class="bi bi-box-arrow-right"></i>&nbsp; Logout
                    </Link>
                  </ul>

                </li>
              </ul>
            </div>
          </div>
        </nav>

      </>
    );
};

export default Unavbar;