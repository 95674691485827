import React from 'react';
import { Link } from 'react-router-dom';
function NotFoundPage() {

  return (
  <>
   <style dangerouslySetInnerHTML={{ __html: "body{background: white ;" }} />
  <div className="container">
    <div className="row mt-4">
    <div className="col-12">
        <h3> <Link to='/'> Saya Gold Avenue </Link></h3>
     
      </div>
    
    </div>
    <div className="row ">
        <div className="col-lg-6 col-12">
            <div className="row">
                <div className="col-12" style={{fontSize:'133px'}}>
              
               <b> Oops! </b> 
  
                </div>

                <div className="col-12">
                <h2>We can't seem to find the page you're looking for.</h2>
               
                </div>
                 <div className="col-12 mt-4">
                 <p >  <b> Error code: 404</b></p>
                 </div>
                 <div className="col-12">
                 Here are some helpful links instead:
                  <br />
                 <Link to='/'><span style={{color:'blue'}}>Home</span></Link> <br />
                 <Link to='/login'><span style={{color:'blue'}}>Login Page</span></Link> <br />
                 <Link to='/register'><span style={{color:'blue'}}>Register Page</span></Link>
                 </div>

            </div>

        
        </div>
        <div className="col-lg-5 col-12 text-center mt-4 ms-5">
         <img src="assets/images/404.gif" alt="" className='w-75 ' />
        </div>
    </div>
  </div>
     
      </>
  );
}

export default NotFoundPage;
